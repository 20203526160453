<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Teams from '@/services/Teams';
    import Games from '@/services/Games';
    import JsonCSV from 'vue-json-csv';
    import ECharts from "vue-echarts";
    import 'echarts/lib/chart/pie';
    import 'echarts/lib/chart/bar';
    import 'echarts/lib/component/legend';
    import 'echarts/lib/component/title';
    import 'echarts/lib/component/tooltip';
    import 'echarts/lib/component/polar';
    import 'echarts/lib/component/toolbox';
    import 'echarts/lib/component/grid';


    export default {
        components: { Layout, PageHeader, "v-chart": ECharts, "downloadCsv": JsonCSV },
        page: {
            title: "Guess the score",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                isBusy: false,
                title: "Guess the score",
                items: [
                    {
                        text: "Fan Experience",
                    },
                    {
                        text: "Guess the score",
                    },
                ],
                selectedMatch: '',
                matches: [],
                sortedMatches: [],
                sortedPredictions: [],
                sortedPredictionsL: 0,
                showLoader:false,
                homeTeamLogo: null,
                awayTeamLogo: null,
                homeTeamName: null,
                awayTeamName: null,
                homeTeamScore: null,
                awayTeamScore: null,
                date_formatted: null,
                totalVotes: null,
                showTotalVotes: true,
                pieChartData: null,
                jsonPredictionsData: [],
                filenameCSV: 'predictions.csv',
                keyLength: [],
                keyVal: [],
                barChartData: {},
            };
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        async created() {
            this.matchesData()
        },
        methods: {
            async getMatches(type){
                try {
                    const response = await Teams.getFixtures(type)
                    if(response.data){
                        return response.data;
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },
            async getCurrentMatches(data){
              try {
                const  response = await Games.getCurrentMatches(data)
                if(response.data){
                    return response.data;
                }
              } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
              }

            },

            async matchesData(){
                this.isBusy = true
                var upcomingMatches = await this.getMatches('UPCOMING');
                var pastMatches = await this.getMatches('PAST');
                var pastMatchTemp = [];
                var upcomingMatchTemp = [];
                this.isBusy = false

                if(upcomingMatches != null && upcomingMatches.fixtures.length > 0){
                    upcomingMatchTemp = upcomingMatches.fixtures;
                }
                if(pastMatches != null && pastMatches.fixtures.length > 0){
                    pastMatchTemp = pastMatches.fixtures;
                }
                this.matches = upcomingMatchTemp.concat(pastMatchTemp);
                this.sort_matches();
                if (this.matches.length > 0) {
                  this.selectedMatch = this.matches[0].match_id;
                  await this.getMatchScorePrediction();
                }
            },

            getMatchScorePrediction(){
                this.showLoader =  true
                this.totalVotes = null;

                if(this.sortedMatches[this.selectedMatch]){
                    this.homeTeamLogo = this.sortedMatches[this.selectedMatch][0].home_team_logo;
                    this.awayTeamLogo = this.sortedMatches[this.selectedMatch][0].away_team_logo;
                    this.homeTeamName = this.sortedMatches[this.selectedMatch][0].home_team_name;
                    this.awayTeamName = this.sortedMatches[this.selectedMatch][0].away_team_name;
                    this.homeTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_home;
                    this.awayTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_away;
                    this.date_formatted = this.sortedMatches[this.selectedMatch][0].date_formatted;
                }else{
                    this.homeTeamLogo = this.awayTeamLogo =  this.awayTeamLogo = this.homeTeamName = this.awayTeamName = '';
                }

                Games.getMatchScorePrediction(this.selectedMatch).then(response => {
                    if(response.data.data){
                        this.editView(response.data.data)
                    }

                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.sortedPredictionsL = 0;
                })
                    .finally(() => {
                    this.showLoader = false
                })
            },

                editView(predictionData){


                    if(predictionData.length > 0){


                    const predictions = predictionData.map((item) => ({
                        home_team_score_guess: item.home_team_score_guess,
                        away_team_score_guess: item.away_team_score_guess,
                        customer_id: item.customer_id,
                        customer_first_name: item.customer_first_name,
                        customer_last_name: item.customer_last_name,
                        customer_email: item.customer_email,
                        match_id: item.match_id,
                        is_match_finished: item.is_match_finished,
                        home_team_score_actual: item.home_team_score_actual,
                        away_team_score_actual: item.away_team_score_actual,
                    }));
                    // Count the votes for each prediction
                    const predictionVotes = {};

                    predictions.forEach((prediction) => {
                        const predictionScore = `${prediction.home_team_score_guess}-${prediction.away_team_score_guess}`;
                        if (predictionVotes[predictionScore]) {
                            predictionVotes[predictionScore]++;
                        } else {
                            predictionVotes[predictionScore] = 1;
                        }
                    });

                    const predictionVotesArray = Object.entries(predictionVotes).map(([predictionScore, votes]) => ({
                        predictionScore,
                        votes,
                    }));


                    // Sort predictions by the number of votes in descending order
                    const sortedPredictions = predictionVotesArray
                        .sort((a, b) => b.votes - a.votes)
                        .slice(0, 5)
                        .map((item) => {
                            const [homeScoreGuess, awayScoreGuess] = item.predictionScore.split('-');
                            return {
                                score_guess:`${parseInt(homeScoreGuess)}-${parseInt(awayScoreGuess)}` ,
                                votes: item.votes,
                            };
                        });



                        var barChartData = sortedPredictions
                        const keys = barChartData.map(item => item.score_guess);
                        const values = barChartData.map(item => item.votes);


                        this.barChartData = {
                            width: '100%',
                            tooltip: {
                                trigger: 'item',
                                formatter: 'Score<br>{b} '
                            },
                            legend: {
                                orient: 'vertical',
                                right: 10,
                                top: 'center',
                            },
                            xAxis: {
                                type: 'category',
                                data: keys,
                                name: 'Predicted Score',
                                axisLabel: {
                                    interval: 0,
                                    align: 'center',
                                    rotate: 30,
                                    margin: 10,
                                }
                            },
                            yAxis: {
                                type: 'value',
                                name: 'Votes',
                            },
                            series: [
                                {
                                    type: 'bar',
                                    barWidth: 30,
                                    data: values.map((value, index) => ({
                                        value,
                                        itemStyle: {
                                            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'][index % 9]
                                        }
                                    }))
                                }
                            ],
                            fontSize: 14,
                            grid: {
                                left: '5%',
                                right: '5%',
                                bottom: '5%',
                                containLabel: true,
                            },
                        };
                    }else{
                        this.barChartData = null
                    }
                    var filename = this.sortedMatches[this.selectedMatch][0].home_team_short+'_'+this.sortedMatches[this.selectedMatch][0].away_team_short+'_'+this.sortedMatches[this.selectedMatch][0].date_time+'.csv'
                    this.filenameCSV = filename.replace(/ /g,"_");

                    this.jsonPredictionsData = predictionData
                    this.totalVotes = predictionData.length

                    this.sortedPredictions = predictionData.reduce(function (r, a) {
                        var scoreKey = a.home_team_score_guess+':'+a.away_team_score_guess;
                        r[scoreKey] = r[scoreKey] || [];
                        r[scoreKey].push(a);
                        return r;
                    }, Object.create(null));


                    this.sortedPredictionsL = Object.keys(this.sortedPredictions).length
                    var arrValues = [];

            },
            sort_matches(){
                this.sortedMatches = this.matches.reduce(function (r, a) {
                    r[a.match_id] = r[a.match_id] || [];
                    r[a.match_id].push(a);
                    return r;
                }, Object.create(null));
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4 d-flex justify-content-center">

                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="homeTeamLogo != null" :src="homeTeamLogo" width="50px" :alt="homeTeamName">
                                    <p class="font-size-16">{{homeTeamName}}</p>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <div class="form-row form-group mb-3" :class="isBusy ? 'placeholder-glow' :''">
                                        <label class="col-md-12 text-center col-form-label">Select Match</label>
                                        <select class="form-select" :class="isBusy ? 'placeholder' :''" v-model="selectedMatch" @change="getMatchScorePrediction()" id="selectMatch">
                                            <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                                                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
                                            </option>
                                        </select>
                                        <div class="row mt-4 w-100">
                                            <div class="col-md-12 text-center " v-if="homeTeamScore != null">
                                                <span class="me-5 text-strong font-size-34">{{homeTeamScore}}</span> : <span class="ms-5 text-strong font-size-34">{{awayTeamScore}}</span>
                                                <p class="p-1 text-strong text-muted">{{date_formatted}}</p>
                                                <p class="m-0" v-if="totalVotes != null">{{totalVotes}} votes in total</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="awayTeamLogo != null" :src="awayTeamLogo" width="50px" :alt="awayTeamName">
                                    <p class="font-size-16">{{awayTeamName}}</p>
                                </div>
                        </div>
                    </div>
                </div>

                <template v-if="!showLoader">

                <div class="card" v-if="sortedPredictionsL > 0">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-md-6">

                                <div class="mb-3">
                                    <h5>Top 5 Predictions Chart</h5>
                                </div>
                                <v-chart :options="barChartData" autoresize />
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <h5>Correct Score Predictions</h5>
                                </div>
                                <div class="col-md-12" v-if="sortedPredictions[homeTeamScore+':'+awayTeamScore]">
                                    <ol>
                                      <div v-for="(item) of sortedPredictions[homeTeamScore + ':' + awayTeamScore]" :key="item.customer_id">
                                        <li>
                                          <router-link :to="{ path: `/customers/profile/${item.customer_id}` }">
                                            {{ item.customer_first_name ? item.customer_first_name + ' ' + item.customer_last_name : item.customer_email }}
                                          </router-link>
                                        </li>
                                      </div>
                                    </ol>
                                </div>
                                <div v-else>
                                    <p>No correct predictions</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3">
                                    <download-csv :data="jsonPredictionsData" :name="filenameCSV" class="btn btn-success waves-effect waves-light"><i class="uil uil-download-alt me-2"></i> Download Predictions </download-csv>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
</template>
<template v-else>
    <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
        <b-spinner large></b-spinner>
    </div>
</template>
            </div>
        </div>

    </Layout>
</template>
